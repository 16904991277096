#users
    box-sizing: border-box
    width: 1080px
    margin: 0 auto
    height: auto
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    .table
        td:last-child
            max-width: 20px
        height: calc(100vh - 150px)
        overflow-y: auto
        overflow-x: hidden
        [data-automation-key="username"]
            font-weight: bold
        [data-automation-key="balance"]
            text-align: right
            font-size: 1.1em
            font-weight: bold
        [data-automation-key="Wrate"], [data-automation-key="CCrate"]
            text-align: center
        [data-automation-key="is_active"], [data-item-key="is_active"]
            width: 90px !important