.editModal
    width: 500px
    top: -30px
    box-sizing: border-box
    .head
        width: 500px
        box-sizing: border-box
        background-color: rgb(0, 120, 212)
        color: white
        padding: 10px 20px
        cursor: move
        display: flex
        flex-direction: row
        justify-content: space-between
    form 
        width: 500px
        box-sizing: border-box
        padding: 10px 0px
        overflow: hidden
        .ms-TextField
           margin-left: 20px
           width: 220px
    .checkboxes
        padding: 20px
        justify-content: flex-start
        .ms-Checkbox
            margin-right: 20px
    .changePassword
        margin-left: 20px
    .actions
        padding: 20px
        justify-content: flex-end
        > button
            margin-right: 20px
            &:last-child
                margin-right: 0px
            &.btc
                color: #999
                &.active
                    background-color: #8e41be
                    color: white
                    &:hover
                        background-color: mix(#8e41be, white, 90)