#home
    box-sizing: border-box
    width: 1080px
    margin: 0 auto
    height: auto
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    ul 
        li 
            list-style-type: none
            font-size: 1.3em
            margin: 20px
            color: black
            a 
                text-decoration: none
                &:visited
                    color: black
                &:hover
                    color: #333