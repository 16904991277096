#notifications
    #anchor
        position: fixed
        width: 0px
        height: 0px
        bottom: 30px
        right: 30px
        z-index: 70
        visibility: hidden
        .ms-Callout.ms-CalloutExample-callout.callout.root-180.css-33
            background-color: white