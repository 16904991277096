::-webkit-scrollbar-track
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
	background-color: #fff

::-webkit-scrollbar
	width: 10px
	background-color: #fff

::-webkit-scrollbar-thumb
	background-color: #fff
	border: 1px solid #8a8886



#menu
	width: 100%
	padding: 5px 0
	border-bottom: 1px solid #eee
	nav
		width: 1080px
		margin: 0 auto
		display: flex
		justify-content: space-between
		align-items: center
		button
			padding: 0
			a
				color: balck
				padding: 0 10px
				text-decoration: none
				&:visited
					color: inherit
				&:focus
					outline: none
		.ms-Button-flexContainer a
			display: flex
			align-items: center
			justify-content: space-between
			padding-right: 10px
			i
				margin-right: 5px
			.ms-Spinner
				margin-right: 5px
				margin-left: -8px
		.logout
			border: none
			color: grey
			transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out
			// background-color: #eee
			&:hover
				color: orangered
				background-color: lightyellow