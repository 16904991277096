.transactionModal
    width: 500px
    top: -30px
    box-sizing: border-box
    .head
        width: 500px
        box-sizing: border-box
        background-color: rgb(0, 120, 212)
        color: white
        padding: 10px 20px
        cursor: move
        display: flex
        flex-direction: row
        justify-content: space-between
    form 
        width: 500px
        box-sizing: border-box
        padding: 10px 0px
        overflow: hidden
        .ms-TextField
           margin-left: 20px
           width: 220px
        .ms-ComboBox
           width: 220px
        .ms-ComboBox-container
            padding-left: 20px
    .row1
        justify-content: flex-start
        .ms-Checkbox
            margin-right: 20px
    .row2
        justify-content: flex-start
        .ms-Checkbox
            margin-right: 20px
    .row3
        justify-content: flex-start
        .partnerAmount
            display: flex
            padding-top: 5px
            flex-direction: column
            label
                padding-left: 20px
                padding-top: 5px
                font-weight: 900
            .amount
                padding-left: 20px
        .calc
            width: 43%
            padding: 29px 0px 0px 25px
            text-align: right
            color: #999
        .ms-Checkbox
            margin-right: 20px
    .actions
        padding: 20px
        justify-content: flex-end
        > button
            &:first-child
                margin-right: 10px
            &:last-child
                margin-left: 10px