#logs
    width: 1080px
    margin: 0 auto
    .logs
        height: calc(100vh - 150px)
        overflow-x: hidden
        overflow-y: auto
        .log
            position: relative
            transition: 0.2s background-color ease-in
            &:hover
                background-color: #f5f5f5
