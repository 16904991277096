@import url(https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar-track{background-color:#fff}::-webkit-scrollbar{width:10px;background-color:#fff}::-webkit-scrollbar-thumb{background-color:#fff;border:1px solid #8a8886}#menu{width:100%;padding:5px 0;border-bottom:1px solid #eee}#menu nav{width:1080px;margin:0 auto;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center}#menu nav button{padding:0}#menu nav button a{color:balck;padding:0 10px;text-decoration:none}#menu nav button a:visited{color:inherit}#menu nav button a:focus{outline:none}#menu nav .ms-Button-flexContainer a{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between;padding-right:10px}#menu nav .ms-Button-flexContainer a i{margin-right:5px}#menu nav .ms-Button-flexContainer a .ms-Spinner{margin-right:5px;margin-left:-8px}#menu nav .logout{border:none;color:gray;transition:.2s color ease-in-out,.2s background-color ease-in-out}#menu nav .logout:hover{color:#ff4500;background-color:#ffffe0}
#notifications #anchor{position:fixed;width:0px;height:0px;bottom:30px;right:30px;z-index:70;visibility:hidden}#notifications #anchor .ms-Callout.ms-CalloutExample-callout.callout.root-180.css-33{background-color:#fff}
#dashboard{width:1080px}#dashboard .bar .balance{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-flex-direction:row;flex-direction:row}#dashboard .bar .balance .txt{color:#777;margin-right:10px}#dashboard .bar .combo{position:relative}#dashboard .bar .combo .clear{font-size:10px;color:#777;position:absolute;bottom:-13px;right:0px}#dashboard .bar .combo .clear:hover{color:#000;cursor:pointer}#dashboard .userInfo,#dashboard .allInfo{margin-top:30px;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between}#dashboard .userInfo table td:first-child,#dashboard .allInfo table td:first-child{text-align:left;color:#777}#dashboard .userInfo table td:last-child,#dashboard .allInfo table td:last-child{text-align:right}
#home{box-sizing:border-box;width:1080px;margin:0 auto;height:auto;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-align-items:center;align-items:center}#home ul li{list-style-type:none;font-size:1.3em;margin:20px;color:#000}#home ul li a{text-decoration:none}#home ul li a:visited{color:#000}#home ul li a:hover{color:#333}
@-webkit-keyframes r{0%{-webkit-transform:rotate(-180deg);transform:rotate(-180deg)}100%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}}@keyframes r{0%{-webkit-transform:rotate(-180deg);transform:rotate(-180deg)}100%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}}#login{width:100vw;height:100vh;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}#login .logo{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:flex-end;align-items:flex-end;padding-bottom:40px}#login .logo img{width:80px;-webkit-animation:r 2s cubic-bezier(0.17, 0.67, 0, 0.98) forwards;animation:r 2s cubic-bezier(0.17, 0.67, 0, 0.98) forwards}#login .logo .Chimera{font-family:"Lexend Deca",Arial,Helvetica,sans-serif;font-weight:900;font-size:4em;padding-left:20px}#login form{width:500px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;-webkit-flex-direction:column;flex-direction:column;border:1px solid #999}#login form .ms-TextField{width:80%;margin:10px}#login form .ms-TextField label{text-align:left}#login form .ms-TooltipHost{color:#1b7dd5;-webkit-align-self:flex-start;align-self:flex-start;margin-left:50px}#login form .ms-TooltipHost:hover{color:#000;cursor:pointer}#login form button.login{margin-top:20px}
.editModal{width:500px;top:-30px;box-sizing:border-box}.editModal .head{width:500px;box-sizing:border-box;background-color:#0078d4;color:#fff;padding:10px 20px;cursor:move;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;justify-content:space-between}.editModal form{width:500px;box-sizing:border-box;padding:10px 0px;overflow:hidden}.editModal form .ms-TextField{margin-left:20px;width:220px}.editModal .checkboxes{padding:20px;-webkit-justify-content:flex-start;justify-content:flex-start}.editModal .checkboxes .ms-Checkbox{margin-right:20px}.editModal .changePassword{margin-left:20px}.editModal .actions{padding:20px;-webkit-justify-content:flex-end;justify-content:flex-end}.editModal .actions>button{margin-right:20px}.editModal .actions>button:last-child{margin-right:0px}.editModal .actions>button.btc{color:#999}.editModal .actions>button.btc.active{background-color:#8e41be;color:#fff}.editModal .actions>button.btc.active:hover{background-color:#9954c5}
.editModal{width:500px;top:-30px;box-sizing:border-box}.editModal .head{width:500px;box-sizing:border-box;background-color:#0078d4;color:#fff;padding:10px 20px;cursor:move;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;justify-content:space-between}.editModal form{width:500px;box-sizing:border-box;padding:10px 0px;overflow:hidden}.editModal form .ms-TextField{margin-left:20px;margin-bottom:10px;width:220px}.editModal form .ms-TextField:last-child{margin-right:20px}.editModal .checkboxes{padding:20px;-webkit-justify-content:flex-start;justify-content:flex-start}.editModal .checkboxes .ms-Checkbox{margin-right:20px}.editModal .actions{padding:40px 20px 20px 20px;-webkit-justify-content:flex-end;justify-content:flex-end}.editModal .actions>button:first-child{margin-right:20px}.editModal .actions>button:last-child{margin-left:20px}
#users{box-sizing:border-box;width:1080px;margin:0 auto;height:auto;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-align-items:center;align-items:center}#users .table{height:calc(100vh - 150px);overflow-y:auto;overflow-x:hidden}#users .table td:last-child{max-width:20px}#users .table [data-automation-key=username]{font-weight:bold}#users .table [data-automation-key=balance]{text-align:right;font-size:1.1em;font-weight:bold}#users .table [data-automation-key=Wrate],#users .table [data-automation-key=CCrate]{text-align:center}#users .table [data-automation-key=is_active],#users .table [data-item-key=is_active]{width:90px !important}
.transactionModal{width:500px;top:-30px;box-sizing:border-box}.transactionModal .head{width:500px;box-sizing:border-box;background-color:#0078d4;color:#fff;padding:10px 20px;cursor:move;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;justify-content:space-between}.transactionModal form{width:500px;box-sizing:border-box;padding:10px 0px;overflow:hidden}.transactionModal form .ms-TextField{margin-left:20px;width:220px}.transactionModal form .ms-ComboBox{width:220px}.transactionModal form .ms-ComboBox-container{padding-left:20px}.transactionModal .row1{-webkit-justify-content:flex-start;justify-content:flex-start}.transactionModal .row1 .ms-Checkbox{margin-right:20px}.transactionModal .row2{-webkit-justify-content:flex-start;justify-content:flex-start}.transactionModal .row2 .ms-Checkbox{margin-right:20px}.transactionModal .row3{-webkit-justify-content:flex-start;justify-content:flex-start}.transactionModal .row3 .partnerAmount{display:-webkit-flex;display:flex;padding-top:5px;-webkit-flex-direction:column;flex-direction:column}.transactionModal .row3 .partnerAmount label{padding-left:20px;padding-top:5px;font-weight:900}.transactionModal .row3 .partnerAmount .amount{padding-left:20px}.transactionModal .row3 .calc{width:43%;padding:29px 0px 0px 25px;text-align:right;color:#999}.transactionModal .row3 .ms-Checkbox{margin-right:20px}.transactionModal .actions{padding:20px;-webkit-justify-content:flex-end;justify-content:flex-end}.transactionModal .actions>button:first-child{margin-right:10px}.transactionModal .actions>button:last-child{margin-left:10px}
.editModal{width:500px;top:-30px;box-sizing:border-box}.editModal .head{width:500px;box-sizing:border-box;background-color:#0078d4;color:#fff;padding:10px 20px;cursor:move;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;justify-content:space-between}.editModal form{width:500px;box-sizing:border-box;padding:10px 0px;overflow:hidden}.editModal form .ms-TextField{margin-left:20px;width:220px}.editModal .checkboxes{padding:20px;-webkit-justify-content:flex-start;justify-content:flex-start}.editModal .checkboxes .ms-Checkbox{margin-right:20px}.editModal .actions{padding:20px;-webkit-justify-content:flex-end;justify-content:flex-end}.editModal .actions>button:first-child{margin-right:20px}.editModal .actions>button:last-child{margin-left:20px}
#transactions{height:auto;width:1080px;margin:0 auto}#transactions .table{height:calc(100vh - 180px);overflow-y:auto;overflow-x:hidden}#transactions .bottom{width:1080px;height:40px;margin:0 auto;position:fixed;bottom:0;background-color:#fff;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between}#transactions .bottom a{text-decoration:none;color:#000}
#logs{width:1080px;margin:0 auto}#logs .logs{height:calc(100vh - 150px);overflow-x:hidden;overflow-y:auto}#logs .logs .log{position:relative;transition:.2s background-color ease-in}#logs .logs .log:hover{background-color:#f5f5f5}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

