#transactions
    height: auto
    width: 1080px
    margin: 0 auto
    .table
        height: calc(100vh - 180px)
        overflow-y: auto
        overflow-x: hidden
    .bottom
        width: 1080px
        height: 40px
        margin: 0 auto
        position: fixed
        bottom: 0
        background-color: white
        display: flex
        justify-content: space-between
        a 
            text-decoration: none
            color: black